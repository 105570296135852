import React, { Component } from "react";
import "./about.scss";
import profile from "../../assets/profile.jpeg";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import badge1 from "../../assets/badge-azure-developer-associate-600x600.png";
import badge2 from "../../assets/badge-azure-solutions-architect-expert-600x600.png";
import badge3 from "../../assets/badge-expert-devops-engineer-600x600.png";
import badge4 from "../../assets/badge-specialty-azure-iot-developer-420x420.webp";
import badge5 from "../../assets/badge-microsoft-certified-professional.png";
import badge6 from "../../assets/badge-mcsd.png";
import badge7 from "../../assets/badge-microsoft-it-professional.png";
import badge8 from "../../assets/badge-mcpd.png";
import ImageModal from "../../components/imageModal/imageModal";

export default class About extends Component {
  render() {
    return (
      <section id="about">
        <div className="banner-text">
          <h1>Ratko Ćosić</h1>
          <h2>Tech Lead | Mentor | Software Architect</h2>
        </div>
        <Container className="container p-3">
          <Row>
            <Col lg={4} xs={12}>
              <ImageModal src={profile} noZoom></ImageModal>
              <h3>A Short Bio</h3>
              <p>
                <em>
                  I am a senior software architect with over 20 years of
                  experience in researching, designing, and developing business
                  systems, primarily on the Microsoft platform. My areas of
                  expertise include communication systems, ERP, and UX products
                  based on cloud computing and related architectural patterns
                  such as microservices, SPAs, serverless, and IoT.
                  Additionally, I serve as a mentor and tech lead. In my spare
                  time, I enjoy giving lectures at regional IT conferences.
                </em>
              </p>
            </Col>
            <Col lg={4} xs={12}>
              <h3>Field of Work</h3>
              <p>
                I work as a <b>technology lead</b>, helping companies grow, and
                as a <b>software architect</b> and developer, contributing to
                project teams in creating a wide variety of business
                applications. With over 20 years of professional experience, I
                have been involved in researching, designing, and developing
                business systems, primarily on the <em>Microsoft</em> platform.
              </p>
              <p>
                I have extensive experience in <em>C#/.NET</em> and frontend
                technologies, including SPAs, meta frameworks, and
                <em>WebAssembly</em>. My work primarily focuses on web platforms
                and cloud solutions. I have contributed to various industries,
                such as communication systems, ERP systems, platform
                applications, packaging and delivery products, and government
                and public sector projects.
              </p>
              <p>
                I have implemented a variety of
                <b> distributed solutions delivered on the Cloud</b> including
                <em>
                  distributed monoliths, SOA, microservices, server-less,
                  event-driven architecture, IoT
                </em>
                I am always passionate about the design and architecture of IT
                projects, and I thrive in development environments based on
                <em>Agile methodology</em>, engaging openly with customers and
                end-users.
              </p>
              <p>
                Additionally, I am a passionate <b>mentor and coach</b>,
                dedicated to inspiring and supporting employees within the
                company. I also have the exciting opportunity to lecture on
                C#/.NET programming at a private university.
              </p>
            </Col>
            <Col lg={4} xs={12}>
              <h3>Accolades</h3>
              <p>
                I’m always eager to learn something new and enhance my knowledge
                and skills. Since my university days, I have continuously
                invested my time in learning new technologies and tools. To
                demonstrate my commitment, I have been training to be a 
                <b> certified Microsoft professional</b> since 2001. Here are
                some of my certifications:
              </p>
              <img className="badger" src={badge1} alt=""></img>
              <img className="badger" src={badge2} alt=""></img>
              <img className="badger" src={badge3} alt=""></img>
              <img className="badger" src={badge4} alt=""></img>
              <img className="badger" src={badge6} alt=""></img>
              <img className="badger" src={badge5} alt=""></img>
              <img className="badger white" src={badge7} alt=""></img>
              <img className="badger white" src={badge8} alt=""></img>
            </Col>
            <Col lg={4} xs={12}>
              <div className="video">
                <iframe
                  className="video"
                  src={`https://www.youtube.com/embed/vGMKgJy2FU8`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded YouTube Video"
                />
              </div>
            </Col>
            <Col lg={8} xs={12}>
              <div className="testimonials">
                <h3>Acknowledgements</h3>
                <em>
                  <Carousel>
                    <Carousel.Item>
                      <article>
                        Ratko is the guy who knows it all. :) There is no
                        unresolvable problem for him. And big advantage which
                        goes in sinergy with that, he's willing to share his
                        knowledge gladly. So, it could be said that he made a
                        few good developers practically from zero.
                        <cite>Ivan Hamonajec</cite>
                      </article>
                    </Carousel.Item>
                    <Carousel.Item>
                      <article>
                        This man's technical knowledge is evident if you take a
                        look at his CV - years and years of experience in
                        various technical roles. I'm not talking about the toxic
                        "we are all family" business culture, I'm talking about
                        the door to Ratko's office that was open to each member
                        of his team and beyond. He would listen, suggest, push
                        in the right direction and sometimes do things that are
                        not in his interest which I found honest and valuable.
                        <cite>Kristijan Cindrić</cite>
                      </article>
                    </Carousel.Item>
                    <Carousel.Item>
                      <article>
                        An experienced tech lead, architect, mentor, and overall
                        expert you can always count on. Not only a seasoned
                        expert, Ratko is also a rare kind of person that brings
                        people together, shares knowledge and is also more than
                        willing to provide quality guidance, advice, and support
                        to his team, company, and community. He is the pillar
                        that will keep your team stable and healthy, while
                        providing reliable results. Such people are rare and of
                        utmost value for any context, especially for collectives
                        that try to solve complex IT problems.
                        <cite>Dino Rudić</cite>
                      </article>
                    </Carousel.Item>
                  </Carousel>
                </em>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
