import React, { Component } from 'react';
import { Button, Row, Container, Col } from 'react-bootstrap';
import './games.scss';
import screenshot1 from '../../assets/dice-and-roll-1-map.png';
import screenshot2 from '../../assets/dice-and-roll-1-battle.png';
import screenshot3 from '../../assets/dice-and-roll-1-loading.png';
import screenshot4 from '../../assets/dice-and-roll-2-logo.png';
import screenshot5 from '../../assets/dice-and-roll-2-battle.png';
import team from '../../assets/dice-and-roll-team.jpg';
import ImageModal from '../../components/imageModal/imageModal';
import { Dice5Fill } from 'react-bootstrap-icons';
import alchemist from '../../assets/alchemist-head.png';
import fey from '../../assets/fey.png';
import assasin from '../../assets/assasin2.png';
import automaton from '../../assets/automaton2.png';

export default class Games extends Component {
  render() {
    return (
      <section id="games">
        <div className="banner-text">
          <h1>Games</h1>
        </div>
        <Container className="panel">
          <Row>
            <Col lg={12}>
              <h3>Dice & Roll</h3>
              <span className="motto">
                A role-playing game written in pure JavaScript and Phaser
              </span>
              <p>
                <em>
                  I have a dear friend named <b>Nikola Begedin</b>, whom I
                  greatly appreciate. Nikola and I worked together at a company,
                  spending countless hours on various JavaScript projects. Over
                  the years, we often talked about our favorite games and how
                  fun it would be to create a simple game together, just to
                  prove we could do it. The daily grind of working for business
                  companies can be a hustle, so we decided to break the monotony
                  by diving into
                  <b> game development with Phaser</b>
                  , a JavaScript-based framework.
                  <br />
                  The result of our free-time efforts was our joint game, which
                  we named <b>"Dice & Roll"</b>. We aimed to create a game with
                  simple mechanics and a bit of chance. The graphics for the
                  game were created by my oldest son,
                  <b> Tvrtko Ćosić</b> - a young teenager at the time, and now a
                  talented biologist!
                  <br />
                  We thoroughly enjoyed the time we spent together, and it was
                  rewarding to see a memorable product come out of it.
                </em>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <ImageModal src={screenshot1} width="360px"></ImageModal>
              <ImageModal src={screenshot2} width="360px"></ImageModal>
              <ImageModal src={screenshot3} width="360px"></ImageModal>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Button
                variant="secondary"
                size="sm"
                className="m-2"
                onClick={() =>
                  window.open(
                    "https://happy-beach-0cf272503.4.azurestaticapps.net",
                    "blank"
                  )
                }
              >
                <Dice5Fill></Dice5Fill>&ensp; Play Dice&Roll Game
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="panel">
          <Row>
            <Col lg={12}>
              <h3>Dice & Roll 2 - A Lighthouse Tale</h3>
              <span className="motto">
                Our second installment, developed with TypeScript, Phaser 3.2,
                and WebPack
              </span>
              <p>
                <em>
                  During our summer family vacation in 2018, while enjoying the
                  fresh mountain air in the Gorski Kotar region of Croatia, an
                  idea came to us: let’s make our own game!
                  <br />
                  Although I was already a senior developer at the time, it
                  didn’t take much to convince me that spending time together on
                  an activity we loved—gaming—was a great idea. I had dabbled in
                  game development before, but I never thought I would pursue it
                  again. My kids, however, took this initiative very seriously
                  and spent their entire summer vacation brainstorming and
                  discussing it. <br />
                  We decided to design a game that would continue the
                  <b> "Dice & Roll" story</b>, but with improved graphics,
                  story, and mechanics. My daughter, <b>Klara Ćosić</b> who had
                  developed an interest in arts and graphic design, joined the
                  team and created some beautiful images for the game. Tvrtko
                  contributed great ideas for the game concept and a world map
                  (we were playing D&D occasionally at the time). Finally, my
                  youngest son, <b>Šimun Ćosić </b>
                  tested and optimized the game mechanics. Klara is now a media
                  university graduate, and Šimun has started studying software
                  engineering.
                  <br />
                  We thoroughly enjoyed our time together, and it was rewarding
                  to see a memorable product come out of it.
                </em>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <ImageModal src={screenshot4}></ImageModal>
            </Col>
            <Col lg={6}>
              <ImageModal src={screenshot5}></ImageModal>
            </Col>
          </Row>
          <Row>
            <Col lg={8} xs={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <ImageModal src={alchemist}></ImageModal>
                  <ImageModal src={automaton}></ImageModal>
                </Col>
                <Col lg={4} xs={6}>
                  <ImageModal src={assasin}></ImageModal>
                </Col>
                <Col lg={5} xs={12}>
                  <ImageModal src={fey}></ImageModal>
                </Col>
              </Row>
            </Col>
            <Col lg={4} xs={12}>
              <ImageModal src={team}></ImageModal>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Button
                variant="secondary"
                size="sm"
                className="m-2"
                onClick={() =>
                  window.open(
                    "https://black-water-0e4bf5e03.4.azurestaticapps.net",
                    "blank"
                  )
                }
              >
                <Dice5Fill></Dice5Fill>&ensp; Play Dice&Roll 2 Game
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}