import React, { Component } from 'react';
import { Badge, Button, Row, Container, Col, Stack } from 'react-bootstrap';
import logoext from '../../assets/ext-translate-logo.jpeg';
import screenshot from '../../assets/ext-translate-screenshot.png';
import './projects.scss';
import ImageModal from '../../components/imageModal/imageModal';
import livechefshot from '../../assets/livechef-screenshot.jpg';

export default class Projects extends Component {
  render() {
    return (
      <section id="projects">
        <div className="banner-text">
          <h1>Projects</h1>
        </div>
        <Container>
          <hr />
          <Row>
            <Col lg={12}>
              <Stack direction="vertical" gap={2} className="article">
                <ImageModal src={logoext} width="120px" />
                <h1>Azure Translator API Action Button</h1>
                <div>
                  <Badge bg="secondary" className="m-2">
                    Azure DevOps
                  </Badge>
                  <Badge>Azure AI Translator API</Badge>
                </div>
                <article className="col-lg-6">
                  A configurable extension that provides an action button for
                  translating fields by using Azure AI Translator API.
                </article>
                <ImageModal src={screenshot} width="800px"></ImageModal>
                <Button
                  variant="outline-warning"
                  size="sm"
                  className="m-2"
                  onClick={() =>
                    window.open(
                      "https://marketplace.visualstudio.com/items?itemName=NoBeard.azure-translator-api-extension",
                      "blank"
                    )
                  }
                >
                  Go to Azure Marketplace
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() =>
                    window.open(
                      "https://github.com/dedalusmax/translate-ext",
                      "blank"
                    )
                  }
                >
                  See the Code on GitHub <i className="fa fa-github ma-2"></i>
                </Button>
              </Stack>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12}>
              <Container>
                <Row>
                  <Col>
                    <div className="p-2">
                      <h1>C#/.NET Learning Projects</h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <h3>Edu Work</h3>
                  </Col>
                  <Col>
                    <article>
                      This is an educational project written in C#/.NET for
                      managing employee working hours in a fictional company. It
                      serves to teach the basics of web application development
                      using Microsoft's web stack - it uses Blazor UI on the
                      client side (leveraging the power of WebAssembly), ASP.NET
                      Web API on the backend, and integration with the MS Entra
                      authentication system."
                    </article>
                  </Col>
                  <Col lg={2}>
                    <Stack direction="horizontal" gap={2}>
                      <Badge>.NET</Badge>
                      <Badge bg="secondary">Blazor</Badge>
                      <Badge bg="dark">WebAssembly</Badge>
                    </Stack>
                  </Col>
                  <Col lg={2}>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() =>
                        window.open(
                          "https://github.com/dedalusmax/EduWork",
                          "blank"
                        )
                      }
                    >
                      See the Code on GitHub{" "}
                      <i className="fa fa-github ma-2"></i>
                    </Button>
                  </Col>
                </Row>
              </Container>
              <small>(more to go ...)</small>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12}>
              <Stack direction="vertical" gap={2} className="article">
                <h1>Live Chef</h1>
                <div>
                  <Badge bg="danger" className="m-2">
                    Angular
                  </Badge>
                  <Badge>TypeScript</Badge>
                  <Badge bg="warning" className="m-2">
                    WebRTC
                  </Badge>
                </div>
                <article className="col-lg-6">
                  This application serves as a demo project developed with{" "}
                  <b>Angular</b> CLI version 1.4.2, utilizing WebRTC. The demo
                  explores scenarios where things might go wrong in the kitchen
                  or when there are no ideas for what to cook. At the 2017 IT
                  conference DevArena, hosted by Ekobit, I presented{" "}
                  <b>WebRTC</b>, Google's free and open project that enables
                  browsers and mobile applications to have Real-Time
                  Communications (RTC) capabilities through simple APIs.
                </article>
                <ImageModal src={livechefshot} width="600px"></ImageModal>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() =>
                    window.open(
                      "https://github.com/dedalusmax/live-chef",
                      "blank"
                    )
                  }
                >
                  See the Code on GitHub <i className="fa fa-github ma-2"></i>
                </Button>
              </Stack>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}