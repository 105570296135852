import './App.scss';
import Header from './pages/header/header';
import About from './pages/about/about';
import Games from './pages/games/games';
import Projects from './pages/projects/projects';
import Sessions from './pages/sessions/sessions';
import Contact from './pages/contact/contact';
import Footer from './pages/footer/footer';

function App() {
  return (
    <div className="app">
        <Header />
        <About />
        <Sessions />
        <Projects />
        <Games />
        <Contact />
        <Footer />
    </div>
  );
}

export default App;
