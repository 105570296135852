import React, { Component } from 'react';
import './sessions.scss';
import { Table, Badge, Stack, Accordion, Container, Col, Row, Carousel } from 'react-bootstrap';
import ImageModal from '../../components/imageModal/imageModal';
import devarena1 from '../../assets/conf-devarena.jpg';
import devarena2 from '../../assets/conf-devarena2.jpg';
import devarena3 from '../../assets/conf-devarena3.jpg';
import weblica1 from '../../assets/conf-weblica.jpg';
import weblica2 from '../../assets/conf-weblica2.webp';
import weblica3 from '../../assets/conf-weblica3.jpg';
import init from '../../assets/conf-init.png';
import online from '../../assets/conf-online.jpg';
import windays1 from '../../assets/conf-windays.jpg';
import windays2 from '../../assets/conf-windays2.jpg';

export default class Sessions extends Component {
  render() {
    return (
      <section id="sessions">
        <div className="banner-text">
          <h1>Sessions</h1>
        </div>
        <Container className="main">
          <Row>
            <Col lg={6} xs={12}>
            <p className="text">
                You can regularly find me either as a <b>speaker</b> on regional
                IT conferences, or as an eager attendee on many IT conferences
                around the world.
              </p>
              <Carousel className="carousel-image">
                <Carousel.Item>
                  <ImageModal src={online}></ImageModal>
                  <Carousel.Caption>
                    <em>Cloud-Native Applications (online session)</em>
                    <div>
                      - with Petra Stručić, Ivan Užarević, and Patricija
                      Potroško, FOI, 2020
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={devarena2}></ImageModal>
                  <Carousel.Caption>
                  <em>Quo vadis frontend? (panel discussion)</em>
                    <div>
                      - Kaptol Centar, Zagreb, 2019
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={windays1}></ImageModal>
                  <Carousel.Caption>
                  <em>WebRTC – real-time communication on the web</em>
                    <div>
                      - WinDays, Umag, 2018
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={weblica1}></ImageModal>
                  <Carousel.Caption>
                    <em>Building powerful apps with Angular 2 and TypeScript</em>
                    <div>
                      - Weblica, Čakovec, 2016
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={init}></ImageModal>
                  <Carousel.Caption>
                    <em>Micro Frontends - Micro-services on the Frontend</em>
                    <div> - INIT, Banja Luka, BiH, 2019</div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={weblica2}></ImageModal>
                  <Carousel.Caption>
                    <em>WebRTC – real-time communication on the web</em>
                    <div>
                      - with Anabel Li Kečkeš, Weblica, Čakovec, 2018
                    </div>
                  </Carousel.Caption>
                </Carousel.Item><Carousel.Item>
                  <ImageModal src={weblica3}></ImageModal>
                  <Carousel.Caption>
                    <em>Weblica - speakers team</em>
                    <div>
                      - Čakovec, 2015.
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={devarena1}></ImageModal>
                  <Carousel.Caption>
                    <em>XAML always and forever</em>
                    <div> - DevArena, Zagreb, 2012</div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={windays2}></ImageModal>
                  <Carousel.Caption>
                  <em>Time for refactoring and rebranding your web apps?</em>
                    <div>
                      - WinDays, Umag, 2017
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <ImageModal src={devarena3}></ImageModal>
                  <Carousel.Caption>
                    <em>WebRTC – real-time communication on the web</em>
                    <div>
                      - with Anabel Li Kečkeš, DevArena, Terme Tuhelj, 2017
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col lg={1} xs={0}></Col>
            <Col lg={5} xs={12}>
              <div className="video">
                <iframe
                  className="video"
                  src={`https://www.youtube.com/embed/zVJAAbl-d3M`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded YouTube Video"
                />
              </div>
              <div className="video">
                <iframe
                  className="video"
                  src={`https://www.youtube.com/embed/s1suSyHmaO4`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded YouTube Video"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Conference</th>
              <th>Title</th>
              <th>Place</th>
              <th>Technologies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>14.12.2024</td>
              <td>
                <a href="https://www.bitcon.hr/">B:IT.con 2024</a>
              </td>
              <td>Razvoj cloud-native aplikacija uz .NET Aspire</td>
              <td>Bjelovar, Croatia</td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <Badge>.NET</Badge>
                  <Badge bg="dark">.NET Aspire</Badge>
                  <Badge bg="info">Azure</Badge>
                </Stack>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>29.11.2024</td>
              <td>
                <a href="https://www.advtechdays.com/">
                  Advanced Technology Days
                </a>
              </td>
              <td>Scalable distributed apps with Microsoft Orleans</td>
              <td>Zagreb, Croatia</td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <Badge bg="danger">Microsoft Orleans</Badge>
                  <Badge>.NET</Badge>
                  <Badge bg="info">Azure</Badge>
                </Stack>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>29.11.2024</td>
              <td>
                <a href="https://www.advtechdays.com/">
                  Advanced Technology Days
                </a>
              </td>
              <td>Full stack web applications using Blazor and .NET</td>
              <td>Zagreb, Croatia</td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <Badge>.NET</Badge>
                  <Badge bg="secondary">Blazor</Badge>
                  <Badge bg="dark">WebAssembly</Badge>
                  <Badge bg="success">Web development</Badge>
                </Stack>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>24.10.2024</td>
              <td>
                <a href="https://www.networkkonferencija.ba/">
                  NetWork Conference
                </a>
              </td>
              <td>What after SPA? Thoughts about modern web development</td>
              <td>Neum, Bosnia and Herzegovina</td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <Badge bg="success">Web development</Badge>
                  <Badge bg="warning">JavaScript</Badge>
                  <Badge bg="secondary">Blazor</Badge>
                  <Badge bg="danger">Angular</Badge>
                  <Badge>React</Badge>
                  <Badge bg="dark">AI</Badge>
                </Stack>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>10.05.2024</td>
              <td>
                <a href="https://weblica.hr/">Weblica Conference</a>
              </td>
              <td>Razvoj cloud-native aplikacija uz .NET 8 i .NET Aspire</td>
              <td>Čakovec, Croatia</td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <Badge bg="success">.NET Aspire</Badge>
                  <Badge>.NET</Badge>
                  <Badge>Cloud development</Badge>
                </Stack>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>10.05.2024</td>
              <td>
                <a href="https://weblica.hr/">Weblica Conference</a>
              </td>
              <td>Što nakon SPA? Moderni razvoj klijentskih web aplikacija</td>
              <td>Čakovec, Croatia</td>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <Badge bg="success">Web development</Badge>
                  <Badge bg="warning">JavaScript</Badge>
                  <Badge bg="secondary">Blazor</Badge>
                  <Badge bg="danger">Angular</Badge>
                </Stack>
              </td>
            </tr>
          </tbody>
        </Table>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2>Past Events</h2>
              <br />
              <small>&nbsp; (still work in progress!)</small>
            </Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Conference</th>
                    <th>Title</th>
                    <th>Place</th>
                    <th>Technologies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>58</td>
                    <td>12/2020</td>
                    <td>
                      <a href="https://www.foi.unizg.hr/">
                        Faculty of Organization and Informatics
                      </a>
                    </td>
                    <td>Cloud-Native Applications</td>
                    <td>Online session</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>.NET</Badge>
                        <Badge bg="info">Docker</Badge>
                        <Badge>Cloud development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>57</td>
                    <td>29.09.2020</td>
                    <td>
                      <a href="https://www.youtube.com/watch?v=_I8n8JPa_nQ">
                        .NET Foundation
                      </a>
                    </td>
                    <td>Microservices and Containerization</td>
                    <td>Online workshop (YouTube)</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>.NET</Badge>
                        <Badge bg="info">Docker</Badge>
                        <Badge>Cloud development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>56</td>
                    <td>02.11.2019</td>
                    <td>
                      <a href="https://2019.initconf.org/">INIT Conference</a>
                    </td>
                    <td>Micro Frontends - Micro-services on the Frontend</td>
                    <td>Banja Luka, Bosnia and Herzegovina</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  {/* https://www.youtube.com/watch?v=s1suSyHmaO4 */}
                  <tr>
                    <td>55</td>
                    <td>24.10.2019</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Quo vadis frontend? [Panel Discussion]</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>54</td>
                    <td>24.10.2019</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>
                      Micro Frontends – arhitektura mikroservisa na frontendu
                    </td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>53</td>
                    <td>24.10.2019</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Angular “Crash Course” </td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>52</td>
                    <td>11.05.2019</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>TypeScript game development w. Phaser 3</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">Phaser</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="dark">Game development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>51</td>
                    <td>11.05.2019</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>
                      Micro Frontends – Microservices Architecture on the
                      Frontend with Angular 8
                    </td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>10.05.2019</td>
                    <td>
                      <a href="https://weblica.hr/worklica/2019/">
                        Worklica Workshops
                      </a>
                    </td>
                    <td>Arhitektura frontend aplikacija uz Angular</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>49</td>
                    <td>18.10.2018</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Phaser 3 - Game Development with TypeScript</td>
                    <td>Terme Tuhelj, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">Phaser</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="dark">Game development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>48</td>
                    <td>18.10.2018</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Serverless Architecture</td>
                    <td>Terme Tuhelj, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Cloud Development</Badge>
                        <Badge bg="success">Serverless</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>47</td>
                    <td>05/2018</td>
                    <td>Microsoft WinDays</td>
                    <td>WebRTC – real-time communication on the web</td>
                    <td>Umag, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="dark">WebRTC</Badge>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>46</td>
                    <td>12.05.2018</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>WebRTC – real-time communication on the web</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="dark">WebRTC</Badge>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                      </Stack>
                    </td>
                  </tr>
                  {/* https://www.youtube.com/watch?v=O3WIL0ahy90 */}
                  <tr>
                    <td>45</td>
                    <td>12.05.2018</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>Progressive Web Applications</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="dark">PWA</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>44</td>
                    <td>2017</td>
                    <td>Microsoft Community</td>
                    <td>Linux for Windows developers</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="warning">Linux</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>43</td>
                    <td>2017</td>
                    <td>Microsoft Community</td>
                    <td>Reinventing a new web stack</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">ASP.NET Core</Badge>
                        <Badge bg="danger">Angular</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>42</td>
                    <td>2017</td>
                    <td>Microsoft WinDays</td>
                    <td>Time for refactoring and rebranding your web apps?</td>
                    <td>Umag, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">ASP.NET Core</Badge>
                        <Badge bg="danger">Angular</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  {/* https://youtu.be/5JRE4x8pbj4 */}
                  <tr>
                    <td>41</td>
                    <td>13.05.2017</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>ASP.NET Core and Angular 2 – Modern Web Development</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">ASP.NET Core</Badge>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>26.10.2017</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Angular “Crash Course” </td>
                    <td>Terme Tuhelj, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>39</td>
                    <td>26.10.2017</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>WebRTC – real-time communication on the web</td>
                    <td>Terme Tuhelj, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="dark">WebRTC</Badge>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>38</td>
                    <td>2016</td>
                    <td>Ekobit</td>
                    <td>Setting Up Angular 2 with ASP.NET Core 1</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">ASP.NET Core</Badge>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>37</td>
                    <td>2016</td>
                    <td>Microsoft WinDays</td>
                    <td>Skype for Business Web Application Development</td>
                    <td>Rovinj, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Skype for Business</Badge>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>36</td>
                    <td>2016</td>
                    <td>
                      <a href="https://www.foi.unizg.hr/">
                        Faculty of Organization and Informatics
                      </a>
                    </td>
                    <td>Static Code Analysis of JavaScript Applications</td>
                    <td>Varaždin, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">Static Code Analysis</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>35</td>
                    <td>14.05.2016</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>
                      Building powerful apps with Angular 2 and TypeScript
                    </td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                    {/* https://www.youtube.com/watch?v=5YevPVzcjzE */}
                  </tr>
                  <tr>
                    <td>34</td>
                    <td>2016</td>
                    <td>Microsoft Community</td>
                    <td>What brings new ASP.NET Core 1 RC2?</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">ASP.NET Core</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>33</td>
                    <td>2016</td>
                    <td>Microsoft Community</td>
                    <td>Migration of code to Angular 2</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td>2016</td>
                    <td>Microsoft Community</td>
                    <td>TypeScript – the next gen JavaScript</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>31</td>
                    <td>2016</td>
                    <td>Microsoft Community</td>
                    <td>Angular 2 – advanced day</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>2016</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Building applications in Angular 2 and TypeScript</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Angular</Badge>
                        <Badge>TypeScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>29</td>
                    <td>2015</td>
                    <td>Microsoft Community</td>
                    <td>Source Control Solutions That Matter</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>VSTS</Badge>
                        <Badge bg="dark">Git</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>28</td>
                    <td>2015</td>
                    <td>Microsoft WinDays</td>
                    <td>Web Essentials – Get More of Web with Visual Studio</td>
                    <td>Rabac, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Visual Studio</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td>09.05.2015</td>
                    <td>
                      <a href="https://weblica.hr/">Weblica Conference</a>
                    </td>
                    <td>ECMA Script 6/7 – The Future is JavaScript</td>
                    <td>Stubičke Toplice, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">Aurelia.JS</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td>2014</td>
                    <td>Microsoft Community</td>
                    <td>Knockout.js</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="dark">Knockout.JS</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td>2014</td>
                    <td>
                      <a href="https://thegeekgathering.org/">
                        The Geek Gathering
                      </a>
                    </td>
                    <td>Real-Time Web Applications with SignalR</td>
                    <td>Osijek, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">SignalR</Badge>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>24</td>
                    <td>2014</td>
                    <td>Microsoft Community</td>
                    <td>Single Page Applications (SPA)</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="danger">Angular</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>23</td>
                    <td>2014</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Building HTML5 Multimedia Applications</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">Multimedia</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>2013</td>
                    <td>Microsoft Community</td>
                    <td>Modern JavaScript Programming</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td>2013</td>
                    <td>Microsoft Community</td>
                    <td>Real-Time Web Applications</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="danger">SignalR</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>2013</td>
                    <td>Microsoft WinDays</td>
                    <td>Lync Communication Applications [Workshop]</td>
                    <td>Poreč, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Lync Communication Services</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>2013</td>
                    <td>Microsoft Community</td>
                    <td>Knockout Applications</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="dark">Knockout.JS</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>2013</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Dungeons & Dragons with HTML5 & SignalR</td>
                    <td>Tuheljske Toplice, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">Web development</Badge>
                        <Badge bg="warning">JavaScript</Badge>
                        <Badge bg="danger">SignalR</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>2012</td>
                    <td>Microsoft Community</td>
                    <td>Application Development on Windows 8</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">Windows development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>2012</td>
                    <td>Microsoft Community</td>
                    <td>Windows 8 – In & Out</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">Windows development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>2012</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>XAML always and forever</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">WPF</Badge>
                        <Badge bg="info">Silverlight</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>2012</td>
                    <td>Microsoft Community</td>
                    <td>BUILD conference – Sneak Peak</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Microsoft</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>2012</td>
                    <td>Microsoft Community</td>
                    <td>Windows Phone 8 – Migration to Mobile Platform</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Windows Phone</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>2011</td>
                    <td>Microsoft Community</td>
                    <td>Building Extensible Applications with MEF</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">MEF</Badge>
                        <Badge>Visual Studio</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>2011</td>
                    <td>Microsoft WinDays</td>
                    <td>Silverlight + MVVM – an easy way (redelivery)</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">Silverlight</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>2011</td>
                    <td>Microsoft Community</td>
                    <td>Silverlight + MVVM – an easy way</td>
                    <td>Čakovec, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">Silverlight</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>2011</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Silverlight 5 – Better and Stronger</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">Silverlight</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>2011</td>
                    <td>Microsoft Community</td>
                    <td>Silverlight 5 RC Tips & Tricks</td>
                    <td>Varaždin, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="info">Silverlight</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                        <Badge bg="success">Web development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>2011</td>
                    <td>Microsoft Community</td>
                    <td>MVVM + Windows Phone 7</td>
                    <td>Varaždin, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Windows Phone</Badge>
                        <Badge bg="secondary">MVVM</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>2010</td>
                    <td>Microsoft Community</td>
                    <td>Windows 7 Features in WPF 4</td>
                    <td>Varaždin, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">WPF</Badge>
                        <Badge bg="secondary">Windows development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>2010</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>WPF 4 - Building Powerful Desktop Applications</td>
                    <td>Tuheljske Toplice, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">WPF</Badge>
                        <Badge bg="secondary">Windows development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>2010</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>Microsoft Extension Framework in Visual Studio 2010</td>
                    <td>Tuheljske Toplice, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="secondary">MEF</Badge>
                        <Badge>Visual Studio</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>2009</td>
                    <td>Microsoft WinDays</td>
                    <td>Static Code Analysis in Visual Studio 2008</td>
                    <td>Opatija, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge>Visual Studio</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>2009</td>
                    <td>
                      <a href="https://devarena.hr/">DevArena Conference</a>
                    </td>
                    <td>A Migration Path to WPF Platform</td>
                    <td>Zagreb, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">WPF</Badge>
                        <Badge bg="secondary">Windows development</Badge>
                      </Stack>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>2008</td>
                    <td>Microsoft Community</td>
                    <td>
                      C# Business Objects – Architecture of Business Application
                      Layer
                    </td>
                    <td>Varaždin, Croatia</td>
                    <td>
                      <Stack direction="horizontal" gap={2}>
                        <Badge bg="success">C#</Badge>
                        <Badge>.NET</Badge>
                        <Badge bg="secondary">Windows development</Badge>
                      </Stack>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    );
    }
  }