import React, { Component } from 'react';
import './header.scss';
import { Navbar, Container, Nav } from "react-bootstrap";

export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header id="home">
          <Navbar id="nav-wrap" expand="lg">
            <Container>
              <Navbar.Toggle
                id="navbar-toggle"
                aria-controls="basic-navbar-nav"
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <Nav.Link href="#about">About</Nav.Link>
                  <Nav.Link href="#sessions">Sessions</Nav.Link>
                  <Nav.Link href="#projects">Projects</Nav.Link>
                  <Nav.Link href="#games">Games</Nav.Link>
                  <Nav.Link href="#contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">NoBeard</h1>
              <span className="h3">Mentoring teams, architecting dreams</span>
              <div className="header-text">
                <h3>
                  What do I stand for?<br></br>Well, it's all about people...
                </h3>
                <hr />
                <span className="motto">
                  I dedicate my time and energy with great passion and
                  commitment to help individuals in organizations master their
                  technology skills, pursue knowledge, and encourage companies
                  to harness their full team potential.
                  <br />I approach each organization, team, and individual
                  uniquely, considering their specific specialties and contexts.
                  Through thoughtful technology guidance, I lead teams to make a
                  meaningful impact on projects and initiatives, thereby
                  enhancing both personal and company value.
                </span>
                <br></br>
                <span className="motto">
                  I base my professional and personal life on faith in God,
                  relying on His eternal wisdom. With an open heart and
                  compassion, I believe that through support and understanding,
                  we can make the lives of others more fruitful and purposeful.
                </span>
                <hr />
                <ul className="social">
                  <li key="linkedin-company">
                    <a
                      href="https://www.linkedin.com/company/nobeard/about/?viewAsMember=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li key="github">
                    <a
                      href="http://github.com/dedalusmax"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-github"></i>
                    </a>
                  </li>
                  <li key="linkedin-personal">
                    <a
                      href="https://www.linkedin.com/in/ratko-cosic/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="scrolldown">
            <a className="smoothscroll" title="Go Down" href="#about">
              <i className="fa fa-chevron-down"></i>
            </a>
          </div>
        </header>
      </React.Fragment>
    );
  }
}